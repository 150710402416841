import axios from 'axios';
import router from "../router/index";

export function request(config) {
  //1、创建axios的实例
  const instance = axios.create({
    //公共url前缀(接口)
    baseURL: 'https://yunxiangqin.lfmate.com:7777',
    // baseURL: 'http://192.168.1.32:7777',
    //超时时间
    timeout: 70000
  });

  //2.1、axios的请求拦截器的作用
  instance.interceptors.request.use(config => {
    // 判断是否存在token
    let token = JSON.parse(localStorage.getItem("userInfo"))
    if (token) {
      config.headers.Authorization = token.Authorization
    }
    // 请求成功 放行
    return config;
  }, err => {
    // 请求失败
    Promise.reject(err);
  })

  //2.2、axios的响应拦截器
  instance.interceptors.response.use(config => {
    //可以过滤掉我们不需要的内容
    return config.data;
  }, err => {
    if (err.response.data.errorCode === 504) {
      router.push("/logout");
    } else {
      alert('响应超时，请检查您的网络！');
    }
    return err.response
  })

  // 3、发送网络请求，axios本身的返回值就是Promise
  return instance(config);
}